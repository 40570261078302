import React from 'react';

const Research = (props) => {
    return(
        <div className="container">
            <h3>My Research at CU</h3>
            <p>
                In graduate school, I performed research in experimental ultrafast optics, which essentially deals with optical pulses which are extremely temporally short, i.e. ultrashort pulses (less than 10<sup>-12</sup> seconds). I conducted my research at the University of Colorado under the direction of Prof. Thomas Schibli.
            </p>
            <h4>Why is ultrafast optics important?</h4>
            <div className="caption">
                <p>
                    <b>Note:</b> I have included sections from my master's thesis - available for download at the bottom of this page - that pertain to these concepts in parentheses, in case you would like more details about a particular topic.
                </p>
            </div>
            <p>
                Ultrashort pulses are often generated by lasers thorugh a process known as "mode locking" (2.2.1). Ultrashort pulses are the shortest man-made events ever created, therefore, they are useful for a wide range of applications which include frequency metrology, specturally-pure microwave generation and spectroscopy (1.1). This is because, in order to make a time-measurement of an event, you need a "clock" which has a precision shorter than the event to be measured. Here's a hypothetical scenario to explain this concept: imagine taking a roadtrip from Denver, CO to Vancouver, BC. According to Google Maps, this trip takes 21 hours and 57 minutues (assuming the Canadian border guards know you're an important scientist, in which case they, of course, just wave you through the border checkpoint in no-time). A typical car's digital clock has one minute of percision, so when you time the trip with your car's clock, you should obtain a time measurement of the trip that is close to 21 hours and 57 minutes. Great! Our hypothetical car's clock is plenty percise enough to accurately measure the length of this drive.
            </p>
            <p>
                However, now imagine that you are bored on this trip while driving across Southern Idaho and decide to use the car's clock to measure the time to travel between the white dashed lines in the center of the highway. You are traveling at Southern Idaho driving speed (~70 mph, depending on your tolerence for speeding tickets) and the lines are ~30 feet apart, so the time it takes to get from one line to the next is: 30 ft ⁄ 70 mph = ~.29 seconds. This is far, far less than the one minute percision of your car's clock, so the measurement you get is either 0 minutes or 1 minute (if the minute happens to tick within that .29 seconds). Obviously, both of these answers are very wrong, which demostrates that in order to accurately measure this event, we would need a timing device with a much greater percision than one minute (like maybe an atomic clock). This concept is the crux of why ultrashort pulses are so useful for measurements of events which occur on very short time scales, such as atomic transitions (1.1.3).
            </p>
            <h4>Frequency Combs</h4>
            <p>
            Another implementation of ultrashort pulses which is useful for a wide-range of applications is the generation of "frequency combs". Frequency combs get their name from the fact that they look like a hair comb in the frequency domain, where each tooth of the comb represents a different frequency (represented by different colors in the figure below). If the frequency comb is properly stabilized, the comb can be used to measure arbitrary frequencies of light (1.1).
            </p>
            <div class="figure">
                <img src="../img/FrequencyComb.png" alt="Frequency comb" />
                <div class="caption">
                    <p>A frequency comb is essentially the frequency-domain picture of an ultrashort pulse. FT stands for "Fourier Transform", which is the mathematical operation which takes the description of the pulse from the time domain (left) to the frequency domain (right)."</p>
                </div>
            </div>
            <p>Frequency combs can also be used to improve the precision of metrologic and spectroscopic instruments. For example, frequency combs can be used to stabilize astronomical spectrometers - potentially allowing astronomers in the near future to precisely determine the red shift of far-away celestial objects. These measurements would allow scientists to either vindicate or repudiate the Theory of Dark Energy. An example of such a setup is shown below.</p>
            <div class="figure">
                <img src="../img/Schibli_AstroCalibration.jpg" alt="Astronomical spectrometer calibration setup" />
                <div class="caption">
                    <p>Astronomical spectrometer calibration setup. From the <i>Nature Photonics</i> article "Combs for Dark Energy" by T.R. Schibli.</p>
                </div>
            </div>
          <h4>My contribution</h4>
          <p>My thesis work revolved around creating a method to stabilize the repetition rate of these light pulses, f<sub>r</sub>, which is the inverse of the period between the emitted pulses. There are several methods (3.1.3) which existed prior to this, but in my method (3.2), I sought to reduce the complexity of these established techniques. The method presented in my thesis was ultimately successful in reducing the timing jitter (which quantifies the degree of fluctuation, or noise, in the repetition rate) by at least four-fold from 100 Hz to 10 MHz.</p>
          <a href="https://luke-charbonneau-personal-website-files.s3-us-west-1.amazonaws.com/Thesis_Luke_Charbonneau_11_3_2016.pdf" target="_blank" type="application/pdf" rel="noopener noreferrer"><button className="btn waves-effect blue">View my thesis</button></a>
          <h4>More Resources</h4>
          <ul className="links">
            <li>A fantastic animation that explains the basics of laser operation: <a href="https://upload.wikimedia.org/wikipedia/commons/5/54/Laser%2C_quantum_principle.ogv" alt="Laser Operation Animation" target="_blank" rel="noopener noreferrer">Video (sound)</a></li>
            <li>A more in-depth explaination of frequency combs by Dr. R&uuml;diger Paschotta: <a href="https://www.rp-photonics.com/frequency_combs.html" alt="RP Photonics - Frequency Combs" target="_blank" rel="noopener noreferrer">RP Photonics Encyclopedia</a></li>
            <li>University of Colorado, Department of Physics: <a href="http://www.colorado.edu/physics/" alt="CU Physics Department" target="_blank" rel="noopener noreferrer">Link</a></li>
          </ul>
        </div>
    );
};
export default Research;
